import React from "react";
import { Link } from "react-router-dom";
import { BsCheckCircleFill } from "react-icons/bs";
import { logoLight } from "../../assets/images";
import { FaAngleDoubleRight } from "react-icons/fa";

export function MentionLegals() {
    return(
    <div className="w-full h-screen flex items-center justify-center">
        <div className="w-1/2 hidden lgl:inline-flex h-full text-white">
          <div className="w-[450px] h-full bg-primeColor px-10 flex flex-col gap-6 justify-center">
            <Link to="/">
              <img src={logoLight} alt="logoImg" className="w-28" />
            </Link>
            <div className="flex flex-col gap-1 -mt-1">
              <h1 className="font-titleFont text-xl font-medium">
              Restez connecté pour en savoir plus.
              </h1>
              <p className="text-base"> En vous connectant, vous êtes avec nous !</p>
            </div>
            <div className="w-[300px] flex items-start gap-3">
              <span className="text-green-500 mt-1">
                <BsCheckCircleFill />
              </span>
              <p className="text-base text-gray-300">
                <span className="text-white font-semibold font-titleFont">
                Commencez rapidement avec EDEN
                </span>
                <br />
                Profitez d'une expérience utilisateur fluide et intuitive, 
                conçue pour vous faire gagner du temps et maximiser votre productivité.
              </p>
            </div>
            <div className="w-[300px] flex items-start gap-3">
              <span className="text-green-500 mt-1">
                <BsCheckCircleFill />
              </span>
              <p className="text-base text-gray-300">
                <span className="text-white font-semibold font-titleFont">
                Fiable pour les acheteurs en ligne
                </span>
                <br />
                Des millions de consommateurs font confiance à EDEN pour 
                leur offrir une expérience rapide, sécurisée et satisfaisante.
              </p>
            </div>
            <div className="w-[300px] flex items-start gap-3">
              <span className="text-green-500 mt-1">
                <BsCheckCircleFill />
              </span>
              <p className="text-base text-gray-300">
                <span className="text-white font-semibold font-titleFont">
                Accédez à tous nos services
                </span>
                <br />
                Nous offrons une gamme complète de solutions pour les grandes surfaces, 
                incluant la gestion des stocks, la logistique, et des options de livraison rapide.
              </p>
            </div>
            <div className="flex items-center justify-between mt-2">
              <Link to="/">
                <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
                  © EDEN
                </p>
              </Link>
              <Link to="/mentions-legales">
              <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Mentions legales
              </p>
              </Link>
              {/* <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
                Privacy
              </p> */}
              {/* <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
                Security
              </p> */}
            </div>
          </div>
        </div>
        <div className="w-full lgl:w-full h-full">
            <div className="mt-4">
                <h1 className="font-titleFont underline underline-offset-4 decoration-[1px] font-semibold text-3xl mdl:text-4xl mb-4">
                  Mentions legales
                </h1>
            </div>
            {/* <div className="w-full lgl:w-full h-screen flex items-center justify-center"> */}
            <div className="w-full lgl:w-full flex">
              <div className="px-6 py-2 w-full h-[600px] flex flex-col overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor">
                <div className="flex flex-col gap-3">
                  <div className="flex flex-col gap-.5">
                    <div className="font-titleFont text-base font-semibold text-gray-600 mx-12">
                    <p> <Link className="text-blue-500"> www.edens.mg </Link> est un site de la société EDEN distribution.</p>

                    <p>NIF : 2012416408 - STAT : 20231 32 2022 0 01993 - RCS : 2022B00161</p>

                    <p>Adresse : Parc Saify Anosizato Antananarivo</p>

                    <h1 className="text-lg text-black flex font-bold"> <span className="mt-1 mr-2"><FaAngleDoubleRight /></span> Création de compte :</h1>
                    <p>-Lors de sa commande ou demande de devis, le client peux creer un compte personnel avec un identifiant dans le formulaire « Creer un compte ».
                    Il est a noter qu'a chaque fois que le client revient dans le site pour faire une commande ou demander un devis, les informations concernant le client seront demandes.
                    </p> 
                    <p className="py-4">-Un seul compte est autorisé par personne (même nom, même adresse postale). En cas de création de comptes multiples, EDEN sera en droit de supprimer les comptes 
                    multiples du client et en informera le client par tous moyens. Toute indication d’identité ou d’adresses erronées entraîne la radiation du compte. Tout client 
                    ayant utilisé son ordinateur pour s'inscrire sous des identités multiples (notamment par l'utilisation d'un script ou de la force brute) verra son compte supprimé.
                    En cas d'oubli de son mot de passe, ou en cas de crainte qu'un tiers ait pu en avoir connaissance, le client peut à tout moment changer son mot de passe.
                    </p>
                    <p>
                    -Il est précisé que le client est seul responsable de son identifiant et de son mot de passe. Il supportera seul les conséquences d'une utilisation par des tiers 
                    qui auraient pu en avoir connaissance, sauf à démontrer que la connaissance de ces informations par un tiers résulte d'une faute du site marchand. La création 
                    d'un compte autorise EDEN à utiliser l'adresse email ou le numéro de portable du client pour lui communiquer toute information concernant ses commandes ou le site internet www.edens.mg
                    </p>

                    <p className="text-black">Le client peux S’abonner aux newsletters du site internet edens.mg.</p>

                    <h1 className="text-lg text-blue-500 flex font-bold py-4">CONDITIONS GENERALES DE VENTE </h1>
                    <h3 className="text-lg text-black flex font-bold">La commande :</h3>

                    Le client est invité à effectuer sa commande parmi l'ensemble des produits proposés sur notre site.

                    Nous confirmons au client le détail de sa commande, le jour et l'heure de réception des produits, la livraison, le mode de paiement (en ligne ou sur les lieux de la livraison) par mail à l'adresse de messagerie renseignée lors de la création de son compte et un numéro de commande est alors affecté au client. La commande devient alors ferme et définitive.
                    Si un produit venait à être indisponible, le montant de la commande sera alors révisé à la baisse.

                    <h3 className="text-lg text-black flex font-bold">Annulation de la commande :</h3>
                    Tant que la commande n'est pas encore en cours de préparation (statut : Confirmée), le Client peut modifier ou annuler intégralement sa commande en contactant directement nos commerciales.

                    <h3 className="text-lg text-black flex font-bold">Les prix :</h3>
                    Les prix des produits proposés sur le site www.edens.mg peuvent être différents de ceux du magasin. ls s'entendent toutes taxes comprises et peuvent faire l'objet de promotions différentes de celles présentes en magasin.
                    Les prix des produits comprennent outre les prix des produits, les frais de manutention et d'emballage des produits. Les prix facturés sont ceux en vigueur au jour de la commande et figurant sur la confirmation écrite de la commande adressée au client.

                    <h3 className="text-lg text-black flex font-bold">Collecte et traitement des données personnelles :</h3>
                    Afin d'assurer le traitement, le suivi et l'archivage des commandes, EDEN doit nécessairement traiter et collecter des données personnelles concernant le client.

                    <h3 className="text-lg text-black flex font-bold">Gestion de la commande :</h3>
                    Le renseignement des données personnelles collectées aux fins de vente à distance est obligatoire pour la confirmation, et la validation de la commande du client ainsi que son suivi et son expédition ; en leur absence, la commande du client ne pourra être traitée.

                    <h3 className="text-lg text-black flex font-bold">Propriété intellectuelle :</h3>
                    Tous les éléments du site www.edens.mg (notamment contenus, code, architecture…) sont protégés au titre du droit d'auteur, des marques ou des brevets. 
                    Leur utilisation sans l'autorisation préalable et écrite d'EDEN ou des autres titulaires de droits est susceptible de constituer une contrefaçon et de donner lieu à des poursuites.

                    En vous connectant sur le site Internet www.edens.mg, vous vous interdisez de copier ou télécharger tout ou partie de son contenu, sauf autorisation préalable et expresse d'EDEN. 
                    Sont notamment interdits : l'extraction, la réutilisation le stockage, la reproduction, la représentation ou la conservation de parties quantitativement ou qualitativement substantielles du site EDEN, sous quelque forme que ce soit, est interdite au titre des droits du producteur de bases de données, et sauf autorisation préalable écrite du directeur de la publication.

                    L'utilisateur qui dispose d'un site Internet à titre personnel et non commercial a la possibilité de placer sur son site un lien simple renvoyant directement à la page du site de EDEN dès lors que le site de EDEN apparaît de façon distincte et non encapsulée dans le site initial. Il ne s'agira pas dans ce cas d'une convention implicite d'affiliation. EDEN se réserve le droit d'interdire tout lien émanant d'un site dont le contenu lui paraîtrait inapproprié, sans que cette interdiction ne soit susceptible d'engager sa responsabilité à quelque titre que ce soit.

                    Dans tous les cas, tout lien, même tacitement autorisé, devra être retiré sur simple demande de EDEN.
                    </div>
                  </div>
                </div>
              <Link
                  to="/"
                  className="mx-72"
                >
                  <p className="flex gap-2 bg-primeColor justify-center py-2 hover:bg-black text-gray-200 hover:text-white cursor-pointer w-full text-base font-medium h-10 rounded-md  duration-300">
                  <span className="pt-1"><FaAngleDoubleRight /></span>
                    Revenir a l'acceuil</p>
                </Link>
              </div>
            </div>
        </div>
      </div>
    )
}