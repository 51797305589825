import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import Product from "../../home/Products/Product";
import axios from "axios";
import { useSelector } from "react-redux";
// import { paginationItems } from "../../../constants";
import { GetProductImage } from "../../../assets/images";

// const items = paginationItems;

function Items({ currentItems, selectedBrands, selectedCategories }) {
  // Filter items based on selected brands and categories
  const filteredItems = currentItems.filter((item) => {
    const isBrandSelected =
      selectedBrands.length === 0 ||
      selectedBrands.some((brand) => brand.title === item.brand);

    // const isCategorySelected = 
    //   selectedCategories.length === 0 ||
    //   selectedCategories.some((category) => category.title === item.FA_CodeFamille);
    const isCategorySelected = selectedCategories.length > 1 ?
      selectedCategories.some((category) => category.title === item.FA_CodeFamille): 
      item ;

    return isBrandSelected && isCategorySelected;
  });

  return (
    <>
      {filteredItems.map((item, index) => (
        <div key={index +1} className="w-full">
          <Product
            _id={item.AR_Ref}
            img={GetProductImage(item.AR_Ref)}
            productName={item.AR_Design}
            price={item.AR_PrixVen}
            color={""}
            badge={item.AR_NewProduct > 0? true : false}
            des={""}
            pdf={item.pdf}
            ficheTech={item.ficheTech}
          />
        </div>
      ))}
    </>
  );
}

const Pagination = ({ itemsPerPage }) => {
  const [itemOffset, setItemOffset] = useState(0);
  const [itemStart, setItemStart] = useState(1);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = products.slice(itemOffset, endOffset);
  const selectedBrands = useSelector(
    (state) => state.orebiReducer.checkedBrands
  );
  const selectedCategories = useSelector(
    (state) => state.orebiReducer.checkedCategorys
  );
  const pageCount = Math.ceil(products.length / itemsPerPage);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Replace with your backend API endpoint to fetch products
        const response = await axios.get('https://edens.mg/product/');
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % products.length;
    const newStart = newOffset + 1; // Adjust the start index

    setItemOffset(newOffset);
    setItemStart(newStart);
  };

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 mdl:gap-4 lg:gap-10">
            <Items
              currentItems={currentItems}
              selectedBrands={selectedBrands}
              selectedCategories={selectedCategories}
            />
          </div>
          <div className="flex flex-col mdl:flex-row justify-center mdl:justify-between items-center">
            <ReactPaginate
              nextLabel=""
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel=""
              pageLinkClassName="w-9 h-9 border-[1px] border-lightColor hover:border-gray-500 duration-300 flex justify-center items-center"
              pageClassName="mr-6"
              containerClassName="flex text-base font-semibold font-titleFont py-10"
              activeClassName="bg-black text-white"
            />

            <p className="text-base font-normal text-lightText">
              Products from {itemStart} to {Math.min(endOffset, products.length)} of{" "}
              {products.length}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Pagination;
