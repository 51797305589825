import React, {useState, useEffect} from "react";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useSelector, useDispatch } from "react-redux";
import { logo } from "../../assets/images";
// import {
//     Accordion,
//     AccordionHeader,
//     AccordionBody,
//   } from "@material-tailwind/react";

  import { FaEye } from "react-icons/fa";
  import { FaEyeSlash } from "react-icons/fa";
  import { FaDownload } from "react-icons/fa";

// import { SlLike } from "react-icons/sl";
import { CgDanger } from "react-icons/cg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function CheckOut() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const items = useSelector((state) => state.orebiReducer.products);
    //total
    const [totalAmt, setTotalAmt] = useState("");
    // const [shippingCharge, setShippingCharge] = useState("");
    useEffect(() => {
      let price = 0;
      items.map((item) => {
        price += item.price * item.quantity;
        return price;
      });
      setTotalAmt(price);
    }, [items]);
    //LOGIN IN CHECKOUT
    const[invited, setInvited] = useState(true)
    const[loged, setLoged] = useState(false)
    const handleLoged = ()=>{
        setLoged(!loged)
        setInvited(!invited)
    }
    //accorion
    const [open, setOpen] = React.useState(1);
    const handleOpen = (value) => setOpen(open === value ? null : value);
    // const [disabledAccordions, setDisabledAccordions] = useState([2, 3, 4]);
    const [disableOne, setDisableOne] = useState(false);
    const [disableTwo, setDisableTwo] = useState(true);
    const [disableThree, setDisableThree] = useState(true);
    const [disableFour, setDisableFour] = useState(true);
    //recuperer doc current piece
    const [currentPiece, setCurrentPiece] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
              const response = await axios.get('https://edens.mg/product/getcurrent-piece');
                setCurrentPiece(response.data[0].Ref_Current);
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          }
          if (!currentPiece) {
            fetchData();
          }
        }, [])
            
      const addFormatPiece = (str) => {
        const number = parseInt(str.replace('CMD', ''), 10); // Récupère le nombre après "CMD"
        const newNumber = number + 1; // Ajoute 1
        return `CMD${newNumber}`; // Concatène avec "CMD"
      };
      let pieceCurrent = currentPiece
      const formattedPiece = pieceCurrent ? addFormatPiece(pieceCurrent) : null;
    //recuperer la date
    const currentDateTime = new Date().toISOString();
    //post information
    const [firstname, setFirstName] = useState("")
    const [lastname, setLastName] = useState("")
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [genre, setGenre] = useState("")
    const [adresse, setAdresse] = useState("")
    const [telephone, setTelephone] = useState("")
    const [pays, setPays] = useState("Madagascar")
    const [region, setRegion] = useState("")
    const [code_postale, setCodePostale] = useState("")
    //Message d'erreur
    const [errorMsg, setErrorMsg] = useState("")

      //show input password value
    const [password, setPassword] = useState("");
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(FaEyeSlash);
    const handleToggle = () => {
        if (type==='password'){
        setIcon(FaEye);
        setType('text')
        } else {
            setIcon(FaEyeSlash)
            setType('password')
        }
    }
/**
 * The function `registerPresInfo` sends form data to a server and performs certain actions based on
 * the response.
 */

    const registerPresInfo = async (e) =>{
        e.preventDefault();
        const formData = new FormData()
        formData.append("firstname", firstname)
        formData.append("lastname", lastname)
        formData.append("email", email)
        formData.append("genre", genre)
        formData.append("password", password)
        formData.append("username", username)
        formData.append("currentPiece", currentPiece)
        formData.append("date_Com", currentDateTime)

        if (firstname && lastname && email && genre && username && password) {
            axios.post('https://edens.mg/product/sendUserInfo', formData)
            .then((response) =>{
                // console.log(response)
                if (response.data.Status === 'success') {
                    toast.success("Information added");
                    //accordion
                    setDisableOne(true)
                    setDisableTwo(false)
                    setDisableThree(true)
                    setDisableFour(true)
                    //creation de compte
                    axios.post('https://edens.mg/product/sendInfoDevis', formData)
                    .then((res) => {
                        if (res.data.Status === 'success') {
                            toast.success("Vous avez maintenant un compte!");
                            axios.post('https://edens.mg/product/update-currentPiece', { newPiece: formattedPiece })
                            .then((updateResponse) => {
                                console.log(updateResponse);
                            })
                            .catch((updateError) => {
                                console.error('Error updating:', updateError);
                                toast.error('Please try again!');
                            });
                            
                        }
                    })
                    .catch((updateError) => {
                        console.error('Error updating:', updateError);
                        toast.error('Please try again!');
                    });
                }
                else{
                    toast.error('Error! Please verify and try again!');
                    setErrorMsg(response.data.message)
                }
            })
            .catch(err => console.log(err))
        }else{
            toast.error("Please? Check all of column!");
        }
    }
    //tester le numero
    const[errPhone, setErrPhone] = useState("");
    //update info
    const updateInfo = async (e) =>{
        e.preventDefault();
        if (telephone.length>=10) {
            const formData = new FormData()
            formData.append("adresse", adresse)
            formData.append("pays", pays)
            formData.append("region", region)
            formData.append("code_postale", code_postale)
            formData.append("telephone", telephone)
            formData.append("totalAmt", totalAmt)
            formData.append("currentPiece", currentPiece)
            formData.append("date_Com", currentDateTime)

            if (adresse && pays && region && code_postale && telephone && currentPiece) {
                axios.post('https://edens.mg/product/update-InfoDevis', formData)
                .then((response) =>{
                    console.log(response)
                    if (response.data.Status === 'updated successfully') {
                        toast.success('Information added successfully');
                        //accordion
                        setDisableOne(true)
                        setDisableTwo(true)
                        setDisableThree(false)
                        setDisableFour(true)
                    }
                    else{
                        toast.error('Error! Please verify and try again!');
                    }
                })
                .catch(err => console.log(err))
            }else{
                toast.error("Please? Check all of column!");
            }
        }else{
            setErrPhone("Veuillez entrer un numero de telephone correct!")
        }
    }
    // axios.defaults.withCredentials = true
    //authentification est insertion information
//detruire la session cookie
    // const handleDeleteCookie = ()=>{
    //     axios.get('https://edens.mg/product/logout')
    //     .then(response => {
    //       console.log("Session deleted!");
    //     }).catch(err=> console.log(err))
    //   }
    //Insertion de donne + authentification
      const registerInfoLoged = (e) => {
        e.preventDefault()
        const formData = new FormData();
        formData.append("email", email);
        formData.append("password", password);

        if (password && email) {
            axios.post('https://edens.mg/product/loginUser', formData)
                .then((response) => {
                    console.log(response);
                    if (response.data.Status === 'Login success') {
                        // Une fois connecté, récupérez les informations de l'utilisateur
                        axios.get('https://edens.mg/product/verify-user', { withCredentials: true })
                            .then(response => {
                                if (response.data.Status === 'Success') {
                                    toast.success("Verification done!");
                                    //useState
                                    setFirstName(response.data.firstname);
                                    setLastName(response.data.lastname);
                                    // D'autres mises à jour d'état si nécessaire

                                    // Enregistrer les informations de l'utilisateur
                                    const formData = new FormData();
                                    formData.append("firstname", response.data.firstname);
                                    formData.append("lastname", response.data.lastname);
                                    formData.append("email", email);
                                    formData.append("currentPiece", currentPiece)
                                    formData.append("date_Com", currentDateTime)
                                    // Utiliser l'email entré lors de la connexion
                                    // Ajoutez d'autres champs nécessaires ici

                                    axios.post('https://edens.mg/product/sendInfoDevis', formData)
                                        .then((response) => {
                                            console.log(response);
                                            if (response.data.Status === 'success') {
                                                toast.success("Information added");
                                                // Exemple d'actions après l'enregistrement des informations
                                                setDisableOne(true)
                                                setDisableTwo(false)
                                                setDisableThree(true)
                                                setDisableFour(true)
                                                //mis a jour
                                                axios.post('https://edens.mg/product/update-currentPiece', { newPiece: formattedPiece })
                                                .then((updateResponse) => {
                                                    console.log(updateResponse);
                                                })
                                                .catch((updateError) => {
                                                    console.error('Error updating:', updateError);
                                                    alert('Error updating! Please try again.');
                                                });
                                            } else {
                                                toast.error('Error! Please verify and try again!');
                                            }
                                        })
                                        .catch(err => console.log(err));

                                } else {
                                    toast.error('Verification failed!');
                                }
                            })
                            .catch(error => {
                                console.error("Error verifying user:", error);
                            });

                    } else {
                        toast.error("Please? Check all of column!");
                    }
                })
                .catch(err => console.log(err));
        } else {
            toast.error("Please? Check all of column!");
        }
    };
    //post commande
    const registerItems = async (e) =>{
        e.preventDefault()
        axios.post('https://edens.mg/product/sendItems', { items, pieceCurrent })
        .then(() =>{
            toast.success("Commande confirmed!");
            setDisableOne(true)
            setDisableTwo(true)
            setDisableThree(true)
            setDisableFour(false)
        }).catch(err => console.error('error occured!', err))
    }

    //generer PDF
        const generatePDF = () => {
            const element = document.getElementById('devis');
            html2canvas(element).then((canvas) => {
              const imgData = canvas.toDataURL('image/png');
              const pdf = new jsPDF();
              const imgWidth = 210; // A4 width in mm
              const pageHeight = 295; // A4 height in mm
              const imgHeight = (canvas.height * imgWidth) / canvas.width;
              let heightLeft = imgHeight;
              let position = 10;
        
              pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
              heightLeft -= pageHeight;
        
              while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
              }
        
              pdf.save('devis.pdf');
            })
            navigate('/shop')
        };

    return(
        <div>
            {disableOne === false &&
            <div className="mb-8">
                <h2 className="text-lg font-semibold my-4">INFORMATIONS PERSONNELLES</h2>
                                        <div className=" flex gap-12">
                                            <div>
                                                <input type="checkbox" className="pt-2.5" checked={invited} onChange={handleLoged}/>
                                                <span className="pl-2">COMMANDER EN TANT QU'INVITÉ</span>
                                            </div>
                                            <div>
                                                <input type="checkbox" className="pt-3" checked={loged} onChange={handleLoged}/>
                                                <span className="pl-2">SE CONNECTER</span>
                                            </div>
                                        </div>
                                        {errorMsg && <div className="bg-red-100 border-2 border-red-500 py-3 my-2 w-1/4">
                                            <p className="text-center font-semibold text-red-500">{errorMsg}</p>
                                        </div>}
                                        {loged === false ? (
                                        <form>
                                            <div className="space-y-12">
                                                <div>

                                                <div>
                                                    {/* firstname */}
                                                    <div>
                                                    <label htmlFor="firstname" className="text-sm font-medium leading-6 text-gray-900">Firstname</label>
                                                    <div className="mt-2">
                                                        <input type="text" name="firstname" id="firstname" className="border-b-2 border-b-black lg:w-80 py-1.5 pl-1"
                                                        onChange={(e)=> setFirstName(e.target.value)}/>
                                                    </div>
                                                    </div>
                                                    {/* lastname */}
                                                    <div className="col-span-4 lg:col-span-3">
                                                    <label htmlFor="lastname" className="text-sm font-medium leading-6 text-gray-900">Lastname</label>
                                                    <div className="mt-2">
                                                        <input type="text" name="lastname" id="lastname" className="border-b-2 border-b-black lg:w-80 py-1.5 pl-1"
                                                        onChange={(e)=> setLastName(e.target.value)}/>
                                                    </div>
                                                    </div>
                                                    {/* EMAIL */}
                                                    <div className="col-span-4">
                                                    <label htmlFor="email" className="text-sm font-medium leading-6 text-gray-900">Email address</label>
                                                    <div className="mt-2">
                                                        <input id="email" name="email" type="text" placeholder="name@example.com" className="border-b-2 border-b-black lg:w-80 py-1.5 pl-1"
                                                        onChange={(e)=> setEmail(e.target.value)}/>
                                                    </div>
                                                    </div>
                                                    {/* GENDER   */}
                                                    <div className="col-span-4 lg:col-span-2">
                                                    <label htmlFor="genre" className=" text-sm font-medium leading-6 text-gray-900">Genre</label>
                                                    <div className="mt-2">
                                                        <select id="genre" name="genre" className="w-full py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                                        onChange={(e)=> setGenre(e.target.value)}>
                                                        <option value="Monsieur">Monsieur</option>
                                                        <option value="Madame">Madame</option>
                                                        </select>
                                                    </div>
                                                    </div>
                                                    {/* Username */}
                                                    <div>
                                                    <label htmlFor="username" className="text-sm font-medium leading-6 text-gray-900">Username</label>
                                                    <div className="mt-2">
                                                        <input type="text" name="username" id="username" className="border-b-2 border-b-black lg:w-80 py-1.5 pl-1"
                                                        onChange={(e)=> setUsername(e.target.value)}/>
                                                    </div>
                                                    </div>
                                                    {/* Password */}
                                                    <div className="col-span-4 lg:col-span-3">
                                                    <label htmlFor="password" className=" text-sm font-medium leading-6 text-gray-900">Password</label>
                                                    <div class="mb-4 mt-2 flex">
                                                        <input
                                                            type={type}
                                                            name="password"
                                                            placeholder="Password"
                                                            className="border-b-2 border-b-black lg:w-80 py-1.5 pl-1"
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            autoComplete="current-password"
                                                        />
                                                        <span class="flex relative justify-around items-center" onClick={handleToggle}>
                                                        
                                                        </span>
                                                    </div>
                                                    </div>

                                                </div>
                                                </div>
                                            </div>
                                            <div className="mt-4">
                                                <button className="w-52 h-10 bg-primeColor text-white text-lg mt-4 hover:bg-black duration-300" onClick={registerPresInfo}>
                                                    Continuer
                                                </button>
                                            </div>
                                        </form>
                                        ):(
                                            <form>
                                                <div className="space-y-12">
                                                <div>
                                                    <div className="mt-2 gap-x-6 gap-y-8 sm:grid-cols-6">
                                                    {/* EMAIL */}
                                                    <div className="col-span-4 lg:col-span-3">
                                                    <label htmlFor="email" className=" text-sm font-medium leading-6 text-gray-900">Email address</label>
                                                    <div className="mt-2">
                                                        <input id="email" name="email" type="text" placeholder="Enter your email adress" className="border-b-2 border-b-black lg:w-80 py-1.5 pl-1"
                                                        onChange={(e)=> setEmail(e.target.value)}/>
                                                    </div>
                                                    </div>
                                                    {/* Password */}
                                                    <div className="col-span-4 lg:col-span-3">
                                                    <label htmlFor="password" className=" text-sm font-medium leading-6 text-gray-900">Password</label>
                                                    <div class="mb-4 mt-2 flex">
                                                        <input
                                                            type={type}
                                                            name="password"
                                                            placeholder="Password"
                                                            className="border-b-2 border-b-black lg:w-80 py-1.5 pl-1"
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            autoComplete="current-password"
                                                        />
                                                        <span class="flex relative justify-around items-center" onClick={handleToggle}>
                                                        
                                                        </span>
                                                    </div>
                                                    </div>
                            
                                                    </div>
                                                </div>
                                                </div>
                                                {/* <div className="mx-auto px-20">
                                                I don't have an account?<a className="cursor-pointer ml-2" onClick={handleShow}>Sign Up</a>
                                                </div> */}
                                                <div className="mt-4">
                                                <button className="w-52 h-10 bg-primeColor text-white text-lg mt-4 hover:bg-black duration-300" onClick={registerInfoLoged}>
                                                    Continuer
                                                </button>
                                            </div>
                                            </form>
                                        )}
            </div>
            }
            {disableTwo === false &&
            <div className="mb-8">
                <h2 className="text-lg font-semibold my-4">ADRESSE</h2>
                                        <form>
                                            <div className="space-y-12">
                                                <div>

                                                <div className="mt-2 gap-x-6 gap-y-8 sm:grid-cols-6">
                                                <div className="col-span-4 lg:col-span-3">
                                                    <label htmlFor="adresse" className=" text-sm font-medium leading-6 text-gray-900">Adress</label>
                                                    <div className="mt-2">
                                                        <input type="text" name="adresse" id="adresse" className="border-b-2 border-b-black lg:w-80 py-1.5 pl-1"
                                                        onChange={(e)=> setAdresse(e.target.value)}/>
                                                    </div>
                                                    </div>

                                                    <div className="col-span-4 lg:col-span-3">
                                                    <label htmlFor="telephone" className=" text-sm font-medium leading-6 text-gray-900">Phone</label>
                                                    <div className="mt-2">
                                                        <input type="text" name="telephone" id="telephone" className="border-b-2 border-b-black lg:w-80 py-1.5 pl-1"
                                                        onChange={(e)=> setTelephone(e.target.value)}/>
                                                    </div>
                                                    {errPhone && 
                                                        <div>
                                                            <p className="text-red-500 font-semibold">{errPhone}</p>
                                                        </div>}
                                                    </div>

                                                    <div className="col-span-4 sm:col-start-1 lg:col-span-2">
                                                    <label htmlFor="pays" className=" text-sm font-medium leading-6 text-gray-900">Country</label>
                                                    <div className="mt-2">
                                                        <input type="text" name="pays" id="pays" className="border-b-2 border-b-black lg:w-80 py-1.5 pl-1" 
                                                            value={pays} onChange={(e)=> setPays(e.target.value)}/>
                                                    </div>
                                                    </div>

                                                    <div className="col-span-4 lg:col-span-2">
                                                    <label htmlFor="region" className=" text-sm font-medium leading-6 text-gray-900">City</label>
                                                    <div className="mt-2">
                                                        <input type="text" name="region" id="region" className="border-b-2 border-b-black lg:w-80 py-1.5 pl-1"
                                                        onChange={(e)=> setRegion(e.target.value)}/>
                                                    </div>
                                                    </div>

                                                    <div className="col-span-4 lg:col-span-2">
                                                    <label htmlFor="code_postale" className=" text-sm font-medium leading-6 text-gray-900">ZIP / Postal code</label>
                                                    <div className="mt-2">
                                                        <input type="text" name="code_postale" id="code_postale" className="border-b-2 border-b-black lg:w-80 py-1.5 pl-1"
                                                        onChange={(e)=> setCodePostale(e.target.value)}/>
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            <div className="mt-4">
                                                <button className="w-52 h-10 bg-primeColor text-white text-lg mt-4 hover:bg-black duration-300" onClick={updateInfo}>
                                                    Continuer
                                                </button>
                                            </div>
                                        </form>
            </div>
            }
            {disableThree === false &&
            <div className="mb-8">
                <h2 className="text-lg font-semibold my-4">CONFIRMATION DE LA COMMANDES</h2>
                                    <div className="relative w-full md-[150px] lg:h-full overflow-x-auto border border-gray-200" id="devis">
                                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                                <tr>
                                                    <th scope="col" className="px-16 py-3">
                                                        Image
                                                    </th>
                                                    <th scope="col" className="text-center">
                                                        Product(s)
                                                    </th>
                                                    <th scope="col" className="text-center">
                                                        Quantity
                                                    </th>
                                                    <th scope="col" className="text-center">
                                                        Price
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                    { items.map(item => (
                                                        <tr key={item._id}>
                                                            <td className="px-16 py-3"><img src={item.image} alt="img product" width={100} /></td>
                                                            <td className="text-center"><span className="text-lg font-semibold"> {item.name} </span></td>
                                                            <td className="text-center"> <span className="text-lg font-semibold">{item.quantity}</span></td>
                                                            <td className="text-center"><span className="text-lg font-semibold">{item.price * item.quantity} MGA </span></td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>

                                        <div className="max-w-7xl gap-4 flex justify-end mt-2">
                                            <div className="w-96 flex flex-col gap-4">
                                            <h1 className="text-2xl font-semibold text-right">Cart totals</h1>
                                            <div>
                                                <p className="flex items-center justify-between border-[1px] border-gray-400 border-b-0 py-1.5 text-lg px-4 font-medium">
                                                Subtotal
                                                <span className="font-semibold tracking-wide font-titleFont">
                                                    {totalAmt} MGA
                                                </span>
                                                </p>
                                                <p className="flex items-center justify-between border-[1px] border-gray-400 border-b-0 py-1.5 text-lg px-4 font-medium">
                                                Shipping Charge
                                                <span className="font-semibold tracking-wide font-titleFont text-green-600">
                                                    FREE
                                                    {/* {shippingCharge} MGA  */}
                                                </span>
                                                </p>
                                                <p className="flex items-center justify-between border-[1px] border-gray-400 py-1.5 text-lg px-4 font-medium">
                                                Total
                                                <span className="font-bold tracking-wide text-lg font-titleFont">
                                                    {totalAmt} MGA
                                                    {/* {totalAmt + shippingCharge} MGA */}
                                                </span>
                                                </p>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="absolute top-[50px] opacity-10 flex justify-center items-center">
                                            <img src={logo} alt="logo" className="w-5/12"/>
                                        </div>

                                    </div>
                                    <div className="mt-4 flex gap-8">
                                        <button className="px-4 h-10 bg-primeColor text-white text-lg hover:bg-black duration-300 flex pt-2" onClick={generatePDF}>
                                            <span className="mr-2 mt-1"><FaDownload /></span> Obtenir un devis et terminer
                                        </button>
                                        <button className="px-4 h-10 bg-primeColor text-white text-lg hover:bg-black duration-300" onClick={registerItems}>
                                            Valider Commande(s)
                                        </button>
                                    </div>
            </div>          
            }
            {
            disableFour === false &&
            <>
            <div className="mb-8 py-8 border-2 border-black">
                <div className="px-4">
                    <h3 className="flex">Merci pour votre commande !</h3>
                    <p>
                    {/* Nous vous remercions de nous faire confiance!<br/> */}
                    <span className="flex my-2 text-orange-400 font-bold"><CgDanger className="text-2xl mr-2"/> A savoir :</span>
                    Veuillez noter que, pour le moment, toutes les commandes passées sur le site web devront être réglées en espèces lors de la livraison.
                    <br/>Si vous avez des préoccupations ou des questions urgentes, n'hésitez pas à nous contacter directement via notre service client.
                    <br/>Merci de votre confiance et à bientôt !<br/>
                    Cordialement,
                    </p>
                    <span>L'équipe d'EDEN distribution.</span>
                </div>
            </div>
            <div className="my-4 flex gap-8">
                <button className="px-4 h-10 bg-primeColor text-white text-lg hover:bg-black duration-300 flex pt-2" onClick={()=>{navigate('/shop')}}>
                    <span className="mr-2 mt-1"></span> Terminer
                </button>
            </div>
            </>
            }
        </div>

    );
};

export default CheckOut;