
import React from "react";
import { CgDanger } from "react-icons/cg";
import { Link } from "react-router-dom";
const NotFound = () => {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-full flex justify-center mt-1/2">
            <div className="text-center">
                <h1 className="flex text-9xl font-bold">4<span className="text-red-600"><CgDanger /></span> <span className="transform scale-x-[-1]">4</span></h1>
                <h3 className="text-3xl uppercase font-bold">Page Not Found</h3>
                <p>La page que vous recherchez n'existe pas.</p>
                <Link to="/"><button className="m-4 bg-blue-500 py-4 px-8 rounded-full font-bold text-white">REVENIR A LA PAGE HOME</button></Link>
            </div>
        </div>
      </div>
    );
  };
export default NotFound;