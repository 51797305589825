import React, { useState, useEffect } from "react";
import axios from "axios";
import Banner from "../../components/Banner/Banner";
import BannerBottom from "../../components/Banner/BannerBottom";
import BestSellers from "../../components/home/BestSellers/BestSellers";
import NewArrivals from "../../components/home/NewArrivals/NewArrivals";
import Sale from "../../components/home/Sale/Sale";
import SpecialOffers from "../../components/home/SpecialOffers/SpecialOffers";
import YearProduct from "../../components/home/YearProduct/YearProduct";

const Home = () => {
     const [newProduct, setNewProduct] = useState([])
     const [loading, setLoading] = useState(true);

    useEffect(()=>{
      const fetchDatas = async ()=>{
        try {
          const response = await axios.get('https://edens.mg/product/')
          setNewProduct(response.data)
        } catch (error) {
          console.error('Erreur survenue lors de la recuperation des donnees', error)
        }
      }
      setTimeout(() => {
        fetchDatas()
        setLoading(false);
      }, 2000);
    }, [])
  
    //filtrer tous les nouveaux produits
    const filterNewProdStatus = newProduct.filter(product => product.AR_NewProduct === 1)
    const filterPromoProdStatus = newProduct.filter(product => product.AR_PromoStatus === 1)

  return (
    <>
    {loading === true ? (
      <div className="w-full mx-auto">
        <div className="flex items-center justify-center h-screen">
          <div className="text-2xl">
            <span className="font-bold">Chargement...</span> 
          </div>
        </div>
      </div>
    )
    :(
    <div className="w-full mx-auto">
      <Banner />
      <BannerBottom />
      <div className="max-w-container mx-auto px-4">
        <Sale />
        <NewArrivals filterNewProdStatus={filterNewProdStatus}/>
        <BestSellers filterPromoProdStatus={filterPromoProdStatus}/>
        <YearProduct />
        <SpecialOffers />
      </div>
    </div>
    )}
    </>
  );
};

export default Home;
