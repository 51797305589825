import React from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import { GetProductImage } from "../../../assets/images/index";

const BestSellers = ({ filterPromoProdStatus }) => {
  return (
    <div className="w-full pb-20">
      <Heading heading="Nos meilleures ventes" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4 gap-10">
      {filterPromoProdStatus.map((product, index) =>(
      <div key={index}>
        
        <Product Key
          _id={product.AR_Ref}
          img={GetProductImage(product.AR_Ref)}
          productName={product.AR_Design}
          price={product.AR_PrixVen}
          color=""
          badge={false}
          des=""
        />
      </div>
      ))}
      </div>

    </div>
  );
};

export default BestSellers;
