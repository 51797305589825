import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const About = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="A propos" prevLocation={prevLocation} />
      <div className="pb-10">
        <h1 className="max-w-[900px] text-base text-lightText mb-2">
          <span className="text-primeColor font-semibold text-lg">EDEN</span>{" "}
          est un leader dans la grande distribution, offrant une vaste gamme de produits
          de qualité dans les grandes surfaces et les épiceries de proximité. Notre mission
          est de fournir une expérience d'achat exceptionnelle avec des prix compétitifs. 
          Nous proposons divers gammes de produits.
          Nous investissons dans des technologies avancées pour améliorer l'expérience client, 
          comme les caisses automatiques et les plateformes en ligne pour des achats pratiques. 
          La durabilité est essentielle pour nous : nous réduisons notre empreinte carbone, promouvons 
          le recyclage et minimisons le gaspillage alimentaire.
          La satisfaction client est notre priorité, avec un service professionnel et un 
          service après-vente réactif. Nous continuons d'étendre notre présence avec de nouveaux 
          magasins et visons à devenir un leader a Madagascar tout en restant fidèles à nos valeurs 
          d'excellence et de service.
          En somme, EDEN est une marque de confiance qui répond aux besoins des clients tout 
          en ayant un impact positif sur la société et l'environnement.
        </h1>
        <Link to="/shop">
          <button className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300">
            Continue Shopping
          </button>
        </Link>
      </div>
    </div>
  );
};

export default About;
