import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { BsCheckCircleFill } from "react-icons/bs";
import { FaRegUserCircle } from "react-icons/fa";
import { FaAngleDoubleRight } from "react-icons/fa";
import { logoLight } from "../../assets/images";
import { toast } from "react-toastify";

const UserProfile = () => {
    //verification de l'utilisateur
    const [auth, setAuth] = useState(false)
    const [id, setId] = useState(false)
    const [name, setName] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState("");
    const navigate = useNavigate()

    // axios.defaults.withCredentials = true
    const token = localStorage.getItem('token');
    useEffect(() => {
      axios.get('https://edens.mg/product/verify-user', { withCredentials: true,
        headers: {
          'Authorization': `Bearer ${token}`
        }
       })
          .then(response => {
              if (response.data.Status === 'Success') {
                  setAuth(true);
                  setId(response.data.id);
                  setName(response.data.username);
                  setFirstName(response.data.firstname);
                  setLastName(response.data.lastname);
                  setEmail(response.data.email);
              } else {
                  setAuth(false);
              }
          })
          .catch(error => {
              console.error("Error verifying user:", error);
              setAuth(false);
          });
  }, [])
  //pour la redirection
  // ============= Initial State Start here =============
  // ============= Initial State End here ===============
  // ============= Error Msg Start here =================
  const [errEmail, setErrEmail] = useState("");
  const [errFirstName, setErrFirstName] = useState(""); 
  const [errLastName, setErrLastName] = useState(""); 
  const [errName, setErrName] = useState(""); 
  
  // ============= Error Msg End here ===================
  const [successMsg, setSuccessMsg] = useState("");
  // ============= Event Handler Start here =============
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };
  const handleName = (e) => {
    setName(e.target.value);
    setErrName("");
  };
  const handleFirstName = (e) => {
    setFirstName(e.target.value);
    setErrFirstName("");
  };
  const handleLastName = (e) => {
    setLastName(e.target.value);
    setErrLastName("");
  };
  // ============= Event Handler End here ===============
  //detruire la session cookie
  const handleDeleteCookie = ()=>{
    axios.get('https://edens.mg/product/logout')
    .then(response => {
      console.log("Session deleted!");
    }).catch(err=> console.log(err))
  }

  const handleUpdate = (e) => {
    e.preventDefault();

    if (!email) {
      setErrEmail("Enter your email");
    }

    if (!name) {
      setErrName("Enter your Username");
    }
    if (!firstName) {
      setErrName("Enter your firstname");
    }
    if (!lastName) {
      setErrName("Enter your lastname");
    }
    // ============== Getting the value ==============
    const formData = new FormData()
    formData.append("id", id)
    formData.append("name", name)
    formData.append("email", email)
    formData.append("firstName", firstName)
    formData.append("lastName", lastName)

    if (email && name && firstName && lastName) {
      axios.post('https://edens.mg/product/update-user', formData)
      .then((response) =>{
          console.log(response)
          if (response.data.Status === 'success') {
            toast.success("La base de donnee a ete mise a jour!");
            handleDeleteCookie()
            navigate('/')
          }
          else{
              console.log('error occured!');
          }
      })
      .catch(err => console.log(err))
    }
  };
  return (
    <div className="w-full h-screen flex items-center justify-center">
      <div className="w-1/2 hidden lgl:inline-flex h-full text-white">
        <div className="w-[450px] h-full bg-primeColor px-10 flex flex-col gap-6 justify-center">
          <Link to="/">
            <img src={logoLight} alt="logoImg" className="w-28" />
          </Link>
          <div className="flex flex-col gap-1 -mt-1">
            <h1 className="font-titleFont text-xl font-medium">
            Restez connecté pour en savoir plus.
            </h1>
            <p className="text-base"> En vous connectant, vous êtes avec nous !</p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
              Commencez rapidement avec EDEN
              </span>
              <br />
              Profitez d'une expérience utilisateur fluide et intuitive, 
              conçue pour vous faire gagner du temps et maximiser votre productivité.
            </p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
              Fiable pour les acheteurs en ligne
              </span>
              <br />
              Des millions de consommateurs font confiance à EDEN pour 
              leur offrir une expérience rapide, sécurisée et satisfaisante.
            </p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
              Accédez à tous nos services
              </span>
              <br />
              Nous offrons une gamme complète de solutions pour les grandes surfaces, 
              incluant la gestion des stocks, la logistique, et des options de livraison rapide.
            </p>
          </div>
          <div className="flex items-center justify-between mt-2">
            <Link to="/">
              <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
                © EDEN
              </p>
            </Link>
            <Link to="/mentions-legales">
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
            Mentions legales
            </p>
            </Link>
            {/* <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Privacy
            </p> */}
            {/* <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Security
            </p> */}
          </div>
        </div>
      </div>
      <div className="w-full lgl:w-1/2 h-full">
        {successMsg ? (
          <div className="w-full lgl:w-[500px] h-full flex flex-col justify-center">
            <p className="w-full px-4 py-10 text-green-500 font-medium font-titleFont">
              {successMsg}
            </p>
            <Link to="/signup">
              <button
                className="w-full h-10 bg-primeColor text-gray-200 rounded-md text-base font-titleFont font-semibold 
            tracking-wide hover:bg-black hover:text-white duration-300"
              >
                Profile
              </button>
            </Link>
          </div>
        ) : (
          <div className="w-full lgl:w-[450px] h-screen flex items-center justify-center">
            <div className="px-6 py-2 w-full h-[90%] flex flex-col justify-center overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor">
              <h1 className="flex font-titleFont underline underline-offset-4 decoration-[1px] font-semibold text-3xl mdl:text-4xl mb-4">
                <span className="mr-2 mt-1"><FaRegUserCircle /></span>Profile
              </h1>
              <form className="flex flex-col gap-3">
                {/* Name */}
                <div className="flex flex-col gap-.5">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    Username
                  </p>
                  <input
                    onChange={handleName}
                    value={name}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="name"
                    placeholder=""
                  />
                  {errName && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errName}
                    </p>
                  )}
                </div>
                {/* Email */}
                <div className="flex flex-col gap-.5">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    Work Email
                  </p>
                  <input
                    onChange={handleEmail}
                    value={email}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="email"
                    placeholder=""
                  />
                  {errEmail && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errEmail}
                    </p>
                  )}
                </div>

                {/* firstname */}
                <div className="flex flex-col gap-.5">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    Firstname
                  </p>
                  <input
                    onChange={handleFirstName}
                    value={firstName}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="name"
                    placeholder=""
                  />
                  {errFirstName && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errFirstName}
                    </p>
                  )}
                </div>
                {/* lastname */}
                <div className="flex flex-col gap-.5">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    Lastname
                  </p>
                  <input
                    onChange={handleLastName}
                    value={lastName}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="name"
                    placeholder=""
                  />
                  {errLastName && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errLastName}
                    </p>
                  )}
                </div>

                <button
                  onClick={handleUpdate}
                  className="bg-primeColor hover:bg-black text-gray-200 hover:text-white cursor-pointer w-full text-base font-medium h-10 rounded-md  duration-300"
                >
                  Modifier mes informations
                </button>
                <Link
                  to="/espace-client"
                >
                  <p className="bg-primeColor text-center py-2 hover:bg-black text-gray-200 hover:text-white cursor-pointer w-full text-base font-medium h-10 rounded-md  duration-300">Gerer mes commandes</p>
                </Link>
                <Link
                  to="/"
                >
                  <p className="flex gap-2 bg-primeColor justify-center py-2 hover:bg-black text-gray-200 hover:text-white cursor-pointer w-full text-base font-medium h-10 rounded-md  duration-300">
                  <span className="pt-1"><FaAngleDoubleRight /></span>
                    Revenir a l'acceuil</p>
                </Link>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
