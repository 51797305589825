import React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import CheckOut from "./checkout";

const Payment = () => {
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Checkout" />
      <div className="pb-2">
        <p>Proceder a la confirmation de la commande.</p>

      </div>
        <div className="container">
          <CheckOut/>
        </div>
    </div>
  );
};

export default Payment;
