import React from "react";
import Slider from "react-slick";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import { GetProductImage } from "../../../assets/images";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";

const NewArrivals = ({ filterNewProdStatus }) => {

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div className="w-full pb-16">
      <Heading heading="Nouveautés" />
      <Slider {...settings}>
      {filterNewProdStatus.map((product, index) =>(
        <div key={index} className="px-2">
          <Product
            _id={product.AR_Ref}
            img={GetProductImage(product.AR_Ref)}
            productName={product.AR_Design}
            price={product.AR_PrixVen}
            color=""
            badge={true}
            des=""
          />
        </div>
      ))}

      </Slider>
    </div>
  );
};

export default NewArrivals;
