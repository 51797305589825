import logo from "./eden.png";
import logoLight from "./eden-blanc.png";
import bannerImgOne from "./banner/aff1.png";
import bannerImgTwo from "./banner/aff2.png";
import bannerImgThree from "./banner/bannerImgThree.webp";
import saleImgOne from "./sale/sale1.png";
import saleImgTwo from "./sale/sale2.png";
import saleImgThree from "./sale/saleImgThree.webp";
// ============== Products Start here ====================
import pdf1 from "../pdf/catalogue.pdf";
// New Arrivals
import newArrOne from "./products/newArrival/newArrOne.webp";
import newArrTwo from "./products/newArrival/newArrTwo.webp";
import newArrThree from "./products/newArrival/newArrThree.webp";
import newArrFour from "./products/newArrival/newArrFour.webp";
//product
import prod_1 from "./products/list-product/DAILY'N FAMILY CAKE CITRON 600G X 12PCS.png"
import prod_2 from "./products/list-product/DAILY'N FAMILY CAKE MIX 600G X 12PCS.png"
import prod_3 from "./products/list-product/DAILY'N FAMILY CAKE NATURE 600G X 12PCS.png"
import prod_4 from "./products/list-product/DAILY'N FAMILY CAKE ORANGE 600G X 12PCS.png"
import prod_5 from "./products/list-product/DAILY'N FAMILY CAKE PEPITE NATURE 600G X 12PCS.png"
import prod_6 from "./products/list-product/KRAX CRACKER CHEES& VEGETABLE 70G 24x6 6035.png"
import prod_7 from "./products/list-product/KRAX CRACKER CHEES& VEGETABLE 140G 24x1 6049.png"
import prod_8 from "./products/list-product/KRAX SALTY CRACKER 70G 24x6 6053.png"
import prod_9 from "./products/list-product/KRAX SALTY CRACKER 140G 24x1 6043.png"
import prod_10 from "./products/list-product/PAPILLON ASSORTIS DE 1kg x 10Sac.png"
import prod_11 from "./products/list-product/PAPILLON CAFE AU LAIT DE 1 KG x 10sac.png"
import prod_12 from "./products/list-product/PAPILLON CAFE AU LAIT DE 150g x 25sac.png"
import prod_13 from "./products/list-product/PAPILLON CARAMELS ASSORTIS DE 150g x 25sac.png"
import prod_14 from "./products/list-product/PAPILLON ECLAIR DE 150g x 25sac.png"
import prod_15 from "./products/list-product/PAPILLON FRUTAS DE 150g x 25sac.png"
import prod_16 from "./products/list-product/PAPILLON GLACE MENTHE DE 1 kg x 10sac.png"
import prod_17 from "./products/list-product/PAPILLON GLACE MENTHE DE 150g x 25sac.png"
import prod_18 from "./products/list-product/PAPILLON JELLINO DE 750g x 12sac.png"
import prod_19 from "./products/list-product/PAPILLON MENTHE FRAICHE DE 1 kg x 10sac.png"
import prod_20 from "./products/list-product/PAPILLON MINI CAPPUCCINO DE 150g x 25sac.png"
import prod_21 from "./products/list-product/PAPILLON SUCETTE MAXI BUM FRAISE 960g x 14sac.png"
import prod_22 from "./products/list-product/PAPILLON SUCETTE PIK PONK DE 1kg x 6boite.png"
import prod_23 from "./products/list-product/SABA BLUE 25g x 150pcs.png"
import prod_24 from "./products/list-product/SABA CITRON 25g x 150pcs.png"
import prod_25 from "./products/list-product/SABA FLORAL 25g x 150pcs.png"
import prod_26 from "./products/list-product/SALEM FAMILY CAKE CHOCO VANILLE 600G X 12PCS.png"
import prod_27 from "./products/list-product/SALEM FAMILY CAKE PEPITE CHOCO 600G X 12PCS.png"
import prod_28 from "./products/list-product/SOLAR MULTI USAGE SOAP APPLE 250Gx48PCS.png"
import prod_29 from "./products/list-product/SOLAR MULTI USAGE SOAP CITRON 250Gx48PCS.png"
import prod_30 from "./products/list-product/SOLAR MULTI USAGE SOAP GRAPE 250Gx48PCS.png"
import prod_31 from "./products/list-product/SOLAR MULTI USAGE SOAP STRAWBERRY 250Gx48PCS.png"
import prod_32 from "./products/list-product/SPAGHETTI DIARI 500Gx20.png"

// Best Sellers
import bestSellerOne from "./products/bestSeller/bestSellerOne.webp";
import bestSellerTwo from "./products/bestSeller/bestSellerTwo.webp";
import bestSellerThree from "./products/bestSeller/bestSellerThree.webp";
import bestSellerFour from "./products/bestSeller/bestSellerFour.webp";
import imprimante1 from "./products/bestSeller/imprimante1.webp";
import imprimante2 from "./products/bestSeller/imprimante2.webp";
import imprimante4 from "./products/bestSeller/imprimante4.webp";
import imprimante5 from "./products/bestSeller/imprimante5.webp";
import imprimante6 from "./products/bestSeller/imprimante6.webp";
import imprimante7 from "./products/bestSeller/imprimante7.webp";
import imprimante8 from "./products/bestSeller/imprimante8.webp";
import imprimante9 from "./products/bestSeller/imprimante9.webp";
import encre1 from "./products/bestSeller/encre1.webp";
import encre2 from "./products/bestSeller/encre2.webp";
import encre3 from "./products/bestSeller/encre3.webp";
import encre4 from "./products/bestSeller/encre4.webp";
import bac1 from "./products/bestSeller/bac1.webp";
import bac2 from "./products/bestSeller/bac2.webp";
import bac3 from "./products/bestSeller/bac3.webp";
import bac4 from "./products/bestSeller/bac4.webp";
import ruban1 from "./products/bestSeller/ruban1.webp";
import ruban2 from "./products/bestSeller/ruban2.webp";
import ruban3 from "./products/bestSeller/ruban3.webp";
import ruban4 from "./products/bestSeller/ruban4.webp";

// Special Offers
import spfOne from "./products/specialOffer/spfOne.webp";
import spfTwo from "./products/specialOffer/spfTwo.webp";
import spfThree from "./products/specialOffer/spfThree.webp";
import spfFour from "./products/specialOffer/spfFour.webp";

// Year Product
import productOfTheYear from "./products/panier.jpg";
// ============== Products End here ======================
import paymentCard from "./payment.png";
import emptyCart from "../images/emptyCart.png";

import IMPRIMANTE_PANTUM_CP2200DW from "../images/products/imprimante/IMPRIMANTE_PANTUM_CP2200DW.webp";
import IMPRIMANTE_PANTUM_BM5100FDW from "../images/products/imprimante/IMPRIMANTE_PANTUM_BM5100FDW.webp";
import IMPRIMANTE_PANTUM_BP5100DN from "../images/products/imprimante/IMPRIMANTE_PANTUM_BP5100DN.webp";
import IMPRIMANTE_PANTUM_M6609N from "../images/products/imprimante/IMPRIMANTE_PANTUM_M6609N.webp";
import IMPRIMANTE_PANTUM_P3300DN from "../images/products/imprimante/IMPRIMANTE_PANTUM_P3300DN.webp";
import IMPRIMANTE_PANTUM_P3300DW from "../images/products/imprimante/IMPRIMANTE_PANTUM_P3300DW.webp";
import IMPRIMANTE_PANTUM_P2509W from "../images/products/imprimante/IMPRIMANTE_PANTUM_P2509W.webp";
import IMPRIMANTE_PANTUM_M6559N from "../images/products/imprimante/IMPRIMANTE_PANTUM_M6559N.webp";
import IMPRIMANTE_PANTUM_P2509 from "../images/products/imprimante/IMPRIMANTE_PANTUM_P2509.webp";

import hp1 from "../images/products/imprimante/hp1.webp";
import hp2 from "../images/products/imprimante/hp2.webp";
import hp3 from "../images/products/imprimante/hp3.webp";

import ricoh1 from "../images/products/imprimante/ricoh1.webp";
import ricoh2 from "../images/products/imprimante/ricoh2.webp";
import ricoh3 from "../images/products/imprimante/ricoh3.webp";
import ricoh4 from "../images/products/imprimante/ricoh4.webp";
import ricoh5 from "../images/products/imprimante/ricoh5.webp";

import espson1 from "../images/products/imprimante/espson1.webp";
import espson2 from "../images/products/imprimante/espson2.webp";

export {
  logo,
  logoLight,
  bannerImgOne,
  bannerImgTwo,
  bannerImgThree,
  saleImgOne,
  saleImgTwo,
  saleImgThree,
  // ===================== Products start here ============
  // New Arrivals
  newArrOne,
  newArrTwo,
  newArrThree,
  newArrFour,

  //product distribution
  prod_1,
  prod_2,
  prod_3,
  prod_4,
  prod_5,
  prod_6,
  prod_7,
  prod_8,
  prod_9,
  prod_10,
  prod_11,
  prod_12,
  prod_13,
  prod_14,
  prod_15,
  prod_16,
  prod_17,
  prod_18,
  prod_19,
  prod_20,
  prod_21,
  prod_22,
  prod_23,
  prod_24,
  prod_25,
  prod_26,
  prod_27,
  prod_28,
  prod_29,
  prod_30,
  prod_31,
  prod_32,

  // Best Sellers
  bestSellerOne,
  bestSellerTwo,
  bestSellerThree,
  bestSellerFour,
  imprimante1,
  imprimante2,
  imprimante4,
  imprimante5,
  imprimante6,
  imprimante7,
  imprimante8,
  imprimante9,
  encre1,
  encre2,
  encre3,
  encre4,
  bac1,
  bac2,
  bac3,
  bac4,
  ruban1,
  ruban2,
  ruban3,
  ruban4,

  // Sprcial Offers
  spfOne,
  spfTwo,
  spfThree,
  spfFour,

  // Year Product
  productOfTheYear,
  // ===================== Products End here ==============
  paymentCard,
  emptyCart,
  pdf1,

  // imprimante pantum
  IMPRIMANTE_PANTUM_CP2200DW,
  IMPRIMANTE_PANTUM_BM5100FDW,
  IMPRIMANTE_PANTUM_BP5100DN,
  IMPRIMANTE_PANTUM_M6609N,
  IMPRIMANTE_PANTUM_P3300DN,
  IMPRIMANTE_PANTUM_P3300DW,
  IMPRIMANTE_PANTUM_P2509W,
  IMPRIMANTE_PANTUM_M6559N,
  IMPRIMANTE_PANTUM_P2509,

  // imprimante Hp
  hp1,
  hp2,
  hp3,

  // imprimante Ricoh
  ricoh1,
  ricoh2,
  ricoh3,
  ricoh4,
  ricoh5,
  espson1,
  espson2,
};


export const ProductImages = {
  CSA003:prod_1,
  CSA007:prod_2,
  CSA004:prod_3,
  CSA002:prod_4,
  CSA005:prod_5,
  SHIR002:prod_6,
  SHIR004:prod_7,
  SHIR001:prod_8,
  SHIR003:prod_9,
  PAP001:prod_10,
  PAP002:prod_11,
  PAP006:prod_12,
  PAP011:prod_13,
  PAP008:prod_14,
  PAP010:prod_15,
  PAP004:prod_16,
  PAP007:prod_17,
  PAP005:prod_18,
  PAP003:prod_19,
  PAP009:prod_20,
  PAP012:prod_21,
  PAP013:prod_22,
  DET003:prod_23,
  DET002:prod_24,
  DET001:prod_25,
  CSA001:prod_26,
  CSA006:prod_27,
  SOL004:prod_28,
  SOL001:prod_29,
  SOL003:prod_30,
  SOL002:prod_31,
  SPAG001:prod_32,
}

export const GetProductImage = (AR_Ref) => {
  // Vérifie si AR_Ref existe dans productImages
  if (ProductImages.hasOwnProperty(AR_Ref)) {
    return ProductImages[AR_Ref];
  }
};