import React, { useState } from "react";
import axios from "axios";
import { BsCheckCircleFill } from "react-icons/bs";
import { FaAngleDoubleRight } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { logoLight } from "../../assets/images";
// import { toast } from "react-toastify";

const SignIn = () => {
  axios.defaults.withCredentials = true;
  const navigate = useNavigate()
  // ============= Initial State Start here =============
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // ============= Initial State End here ===============
  // ============= Error Msg Start here =================
  const [errEmail, setErrEmail] = useState("");
  const [errPassword, setErrPassword] = useState("");

  // ============= Error Msg End here ===================
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  // ============= Event Handler Start here =============
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
    setErrPassword("");
  };
  // ============= Event Handler End here ===============
    //detruire la session cookie
      // const handleDeleteCookie = ()=>{
      //   axios.get('https://edens.mg/product/logout')
      //   .then(response => {
      //     console.log("Session deleted!");
      //   }).catch(err=> console.log(err))
      // }

  const handleSignUp = (e) => {
    e.preventDefault();

    if (!email) {
      setErrEmail("Enter your email");
    }

    if (!password) {
      setErrPassword("Create a password");
    }
    // ============== Getting the value ==============
    const formData = new FormData()
    formData.append("email", email)
    formData.append("password", password)

    if (email && password) {
      axios.post('https://edens.mg/product/loginUser', formData)
      .then((response) =>{
          console.log(response)
          if (response.data.Status === 'Login success') {
            navigate('/')
          }
          else{
            setErrorMsg("votre email et/ou password est incorrect")
          }
      })
      .catch(err => console.log(err))

      setEmail("");
      setPassword("");
    }
  };
  return (
    <div className="w-full h-screen flex items-center justify-center">
      <div className="w-1/2 hidden lgl:inline-flex h-full text-white">
        <div className="w-[450px] h-full bg-primeColor px-10 flex flex-col gap-6 justify-center">
          <Link to="/">
            <img src={logoLight} alt="logoImg" className="w-28" />
          </Link>
          <div className="flex flex-col gap-1 -mt-1">
            <h1 className="font-titleFont text-xl font-medium">
            Restez connecté pour en savoir plus.
            </h1>
            <p className="text-base"> En vous connectant, vous êtes avec nous !</p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
              Commencez rapidement avec EDEN
              </span>
              <br />
              Profitez d'une expérience utilisateur fluide et intuitive, 
              conçue pour vous faire gagner du temps et maximiser votre productivité.
            </p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
              Fiable pour les acheteurs en ligne
              </span>
              <br />
              Des millions de consommateurs font confiance à EDEN pour 
              leur offrir une expérience rapide, sécurisée et satisfaisante.
            </p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
              Accédez à tous nos services
              </span>
              <br />
              Nous offrons une gamme complète de solutions pour les grandes surfaces, 
              incluant la gestion des stocks, la logistique, et des options de livraison rapide.
            </p>
          </div>
          <div className="flex items-center justify-between mt-2">
            <Link to="/">
              <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
                © EDEN
              </p>
            </Link>
            <Link to="/mentions-legales">
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
            Mentions legales
            </p>
            </Link>
            {/* <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Privacy
            </p> */}
            {/* <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Security
            </p> */}
          </div>
        </div>
      </div>
      <div className="w-full lgl:w-1/2 h-full">
        {successMsg ? (
          <div className="w-full lgl:w-[500px] h-full flex flex-col justify-center">
            <p className="w-full px-4 py-10 text-green-500 font-medium font-titleFont">
              {successMsg}
            </p>
            <Link to="/signup">
              <button
                className="w-full h-10 bg-primeColor text-gray-200 rounded-md text-base font-titleFont font-semibold 
            tracking-wide hover:bg-black hover:text-white duration-300"
              >
                S'inscrire
              </button>
            </Link>
          </div>
        ) : (
          <form className="w-full lgl:w-[450px] h-screen flex items-center justify-center">
            <div className="px-6 py-2 w-full h-[90%] flex flex-col justify-center overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor">
              {errorMsg && <div className="my-2">
              <p className="w-full px-4 py-10 text-red-500 font-medium font-titleFont">{errorMsg}</p>
              </div>}
              <h1 className="font-titleFont underline underline-offset-4 decoration-[1px] font-semibold text-3xl mdl:text-4xl mb-4">
                Se connecter
              </h1>
              <div className="flex flex-col gap-3">
                {/* Email */}
                <div className="flex flex-col gap-.5">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    Work Email
                  </p>
                  <input
                    onChange={handleEmail}
                    value={email}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="email"
                    placeholder="john@workemail.com"
                  />
                  {errEmail && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errEmail}
                    </p>
                  )}
                </div>

                {/* Password */}
                <div className="flex flex-col gap-.5">
                  <p className="font-titleFont text-base font-semibold text-gray-600">
                    Password
                  </p>
                  <input
                    onChange={handlePassword}
                    value={password}
                    className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                    type="password"
                    placeholder="Create password"
                  />
                  {errPassword && (
                    <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                      <span className="font-bold italic mr-1">!</span>
                      {errPassword}
                    </p>
                  )}
                </div>

                <button
                  onClick={handleSignUp}
                  className="bg-primeColor hover:bg-black text-gray-200 hover:text-white cursor-pointer w-full text-base font-medium h-10 rounded-md  duration-300"
                >
                  Se connecter
                </button>
                <p className="text-sm text-center font-titleFont font-medium">
                  Je n'ai pas de compte?{" "}
                  <Link to="/signup">
                    <span className="hover:text-blue-600 duration-300">
                      S'inscrire
                    </span>
                  </Link>
                </p>
                <Link
                  to="/"
                  className="flex justify-center py-2 gap-2 text-black w-full text-base font-bold hover:underline h-10 rounded-md">
                  <span className="pt-1"><FaAngleDoubleRight /></span>
                    <p>Revenir a l'acceuil</p>
                </Link>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default SignIn;
