import React, { useState, useEffect } from "react";
import axios from "axios";
// import { FaPlus } from "react-icons/fa";
import { ImPlus } from "react-icons/im";
import NavTitle from "./NavTitle";
import { useDispatch, useSelector } from "react-redux";
import { toggleCategory } from "../../../../redux/orebiSlice";

const Category = ({ icons }) => {
  const [showSubCatOne, setShowSubCatOne] = useState(false);

  const checkedCategorys = useSelector(
    (state) => state.orebiReducer.checkedCategorys
  );
  const dispatch = useDispatch();

  const [listCategories, setListCategories] = useState([]);

  useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get('https://edens.mg/product/categorie/');
          setListCategories(response.data);
        } catch (error) {
          // console.error('Error fetching data:', error);
            console.log(error);
        }
      };
  
      fetchData();
    }, []);


  const handleToggleCategory = (listCategories) => {
    dispatch(toggleCategory(listCategories));
  };

  return (
    <div className="w-full">
      <NavTitle title="Shop by Category" icons={true} />
      <div>
        <ul className="flex flex-col gap-4 text-sm lg:text-base text-[#767676]">
          {listCategories.map((item, index) => (
            <li
              key={index + 1}
              className="border-b-[1px] border-b-[#F0F0F0] pb-2 flex items-center gap-2 hover:text-primeColor hover:border-gray-400 duration-300"
            >
              <input
                type="checkbox"
                id={item.FA_CodeFamille}
                checked={checkedCategorys.some((b) => b.title === item.FA_CodeFamille)}
                onChange={() => handleToggleCategory({ _id: item.FA_CodeFamille, title: item.FA_CodeFamille })}
              />
              {item.FA_CodeFamille}
              {icons && (
                <span
                  onClick={() => setShowSubCatOne(!showSubCatOne)}
                  className="text-[10px] lg:text-xs cursor-pointer text-gray-400 hover:text-primeColor duration-300"
                >
                  <ImPlus />
                </span>
              )}
            </li>
          ))}
          <li onClick={() => console.log(checkedCategorys)}></li>
        </ul>
      </div>
    </div>
  );
};

export default Category;
