import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { BsCheckCircleFill } from "react-icons/bs";
import { logoLight } from "../../assets/images";
import { toast } from "react-toastify";

const EspaceClient = () => {
    //verification de l'utilisateur
    const [auth, setAuth] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState("");
    // useState message success
    const [succMsg, setSuccMsg] = useState("");

    useEffect(() => {
      axios.get('https://edens.mg/product/verify-user', {withCredentials: true})
          .then(response => {
              if (response.data.Status === 'Success') {
                  setAuth(true);
                  setFirstName(response.data.firstname);
                  setLastName(response.data.lastname);
                  setEmail(response.data.email);
              } else {
                  setAuth(false);
              }
          })
          .catch(error => {
              console.error("Error verifying user:", error);
              setAuth(false);
          });
  }, [])
  // // ============== Getting the value ==============
  const [clientData, setClientData] = useState([]);

  useEffect(() => {
    const fetchDatas = async () => {
      try {
        const response = await axios.get('https://edens.mg/product/commande-client');
        setClientData(response.data);
      } catch (error) {
        console.error('Erreur survenue lors de la recuperation des donnees', error);
      }
    };
    fetchDatas();
  }, []);

  const handleDelete = async (id_prod) => {
      await axios.post('https://edens.mg/product/delete-commande', { id_prod })
      // Supprimer localement l'élément de clientData après une suppression réussie
    //   setClientData(clientData.filter(data => data.id_prod !== id_prod));
    .then((res)=>{
        if (res.data.Status === "Success") {
            setSuccMsg(res.data.message)
            setTimeout(() => {
                window.location.reload();
            }, 2000); 
        }
    })
    .catch ((error)=> {
      console.error('Erreur survenue lors de la suppression', error);
    })
  };

  return (
    <div className="w-full h-screen flex items-center justify-center">
      <div className="w-1/2 hidden lgl:inline-flex h-full text-white">
        <div className="w-[450px] h-full bg-primeColor px-10 flex flex-col gap-6 justify-center">
          <Link to="/">
            <img src={logoLight} alt="logoImg" className="w-28" />
          </Link>
          <div className="flex flex-col gap-1 -mt-1">
            <h1 className="font-titleFont text-xl font-medium">
            Restez connecté pour en savoir plus.
            </h1>
            <p className="text-base"> En vous connectant, vous êtes avec nous !</p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
              Commencez rapidement avec EDEN
              </span>
              <br />
              Profitez d'une expérience utilisateur fluide et intuitive, 
              conçue pour vous faire gagner du temps et maximiser votre productivité.
            </p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
              Fiable pour les acheteurs en ligne
              </span>
              <br />
              Des millions de consommateurs font confiance à EDEN pour 
              leur offrir une expérience rapide, sécurisée et satisfaisante.
            </p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
              Accédez à tous nos services
              </span>
              <br />
              Nous offrons une gamme complète de solutions pour les grandes surfaces, 
              incluant la gestion des stocks, la logistique, et des options de livraison rapide.
            </p>
          </div>
          <div className="flex items-center justify-between mt-2">
            <Link to="/">
              <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
                © EDEN
              </p>
            </Link>
            <Link to="/mentions-legales">
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
            Mentions legales
            </p>
            </Link>
            {/* <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Privacy
            </p> */}
            {/* <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Security
            </p> */}
          </div>
        </div>
      </div>
      <div className="w-full lgl:w-full h-full mx-12 mt-4">
        {auth ===true  &&  <div className="w-full lgl:w-full h-screen">
              <h1 className="font-titleFont underline underline-offset-4 decoration-[1px] font-semibold text-3xl mdl:text-4xl mb-4">
              Liste des commandes
              </h1>
              {succMsg &&
              <div>
                <p className="text-green-500">{succMsg}</p>
              </div>
              }
              <div className="overflow-x-auto">
                <table className="w-full border border-black">
                    <thead className="border border-black">
                        <tr>
                        <th>Designation</th>
                        <th>Quantite</th>
                        <th>Prix</th>
                        <th>Date</th>
                        <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {clientData.filter(data => data.email === email && data.firstname === firstName && data.lastname === lastName).map((data, index) => (
                        <tr key={index} className="text-center">
                            <td className="text-left pl-3">{data.AR_Design}</td>
                            <td>{data.AR_Qte} <span className="hidden">{data.id_prod}</span></td>
                            <td>{data.AR_PrixVen}</td>
                            <td>{data.date_Com}</td>
                            <td>
                            <button
                                className="bg-red-500 hover:bg-black text-gray-200 hover:text-white cursor-pointer w-full text-base font-medium h-8"
                                onClick={() => handleDelete(data.id_prod)}
                            >
                                Annuler
                            </button>
                            </td>
                        </tr>
                    ))}

                    </tbody>
                </table>
              </div>
          </div>}
      </div>
    </div>
  );
};

export default EspaceClient;
