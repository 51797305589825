import React, { useEffect, useState } from "react";
import axios from "axios";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
// import { SplOfferData } from "../../../constants";
import { useParams } from "react-router-dom";
// import { prod_1 } from "../../../assets/images";
import { GetProductImage } from "../../../assets/images";

const SpecialOffers = () => {
  const { category } = useParams();

  const [data, setData] = useState([]);
  // useEffect(() => {
  //   setData(SplOfferData);
  // }, [data]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Replace with your backend API endpoint to fetch products
        const response = await axios.get('https://edens.mg/product/');
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const catData = data.filter((item) => item.FA_CodeFamille === category);
  return (
    <div className="w-full pb-20">
      <Heading heading="Offres speciales" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-3 gap-10">
        {catData.map((data, index) => (
          <Product
            key={index}
            _id={data.AR_Ref}
            img={GetProductImage(data.AR_Ref)}
            productName={data.AR_Design}
            price={data.AR_PrixVen}
            color={""}
            badge={data.AR_NewProduct > 0? true : false}
            des={""}
          />
        ))}
      </div>
    </div>
  );
};

export default SpecialOffers;
